/* eslint-disable */
  const required = (propertyType, customErrorMessage) => {
    return v => (v && v.length > 0) || customErrorMessage || `Bạn phải điền dữ liệu vào ${propertyType}`
  }
  const emailFormat = (customErrorMessage) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
    return v => (v && regex.test(v)) || customErrorMessage || 'Thông tin email không hợp lệ !'
  }
  const OrderID = (customErrorMessage) =>{
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return v => (v && regex.test(v)) || customErrorMessage || 'OrderID không hợp lệ !'
  }
  export default {
    required,
    emailFormat,
    OrderID
  }
