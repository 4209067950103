<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <p class="subheading font-weight-regular">
          Please enter your order and your email address (Paypal Address incase you purchase via Paypal)
          
          <br />
          <v-form v-model="valid">
            <v-text-field v-model="request.orderid" label="Order ID" placeholder="Example: 1b104d40-1a03-11e8-b66e-e1ab622befc5" :rules="[OrderID('Please enter valid OrderID !!!')]" />
            <br />
            <v-text-field v-model="request.email" label="Email" :rules="[emailFormat('Please enter valid Email !!!')]" />
            <br />
            <v-textarea label="YOUR KEY" v-if="key !== ''" v-model="key" outlined />
            <br />
            <vue-recaptcha ref="recaptcha" sitekey="6LfA-KUbAAAAABSLmqaLbmc_4spHN0lUVsjUL5gN" :loadRecaptchaScript="true" size="invisible" @verify="onVerify" @expired="onExpired">
              <v-btn outlined color="blue" :disabled="!valid" :loading="loading">GET KEY</v-btn>
            </vue-recaptcha>
          </v-form>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import validations from '@/plugins/validations';
  export default {
    name: 'HelloWorld',
    components: { VueRecaptcha },
    data: () => ({
      request:{
        orderid: '',
        email: '',
        recaptcha: '',
      }, 
      key: '',
      valid: false,
      loading:false,
    }),
    methods:{
      ...validations,
      onVerify: function (response) {
        if(response.length > 0) {
          this.request.recaptcha = response;
          this.loading = true;
          //console.log('Verify: ' + response)
          fetch('https://backend.egmokka.com/order', {
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.request),
          })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if(data.success === true && data.key) {
              this.key = data.key
            }else{
              const msg = data.error || "Error when get Order Data";
              this.$toast.error(msg)
            }
          })
          .catch(err=>{
            this.$toast.error(err)
          })
          .finally(()=>{
            this.loading = false
          })
        }
      },
      onExpired: function () {
        this.$refs.recaptcha.reset();
      },
    }
  }
</script>
